import {
    EmailInputWithLabelAndError,
    PhoneInputWithLabelAndError,
    SecondaryButton
} from "@collabodoc/component-library";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import {useEffect, useState} from "react";
import validator from "validator";
import {InvitationFailedModal} from "./InvitationFailedModal";
import {Header, HeaderText} from "../Content/Style/styleElements";

export const ExternalInvite = ({
                                   handleSmsInvitation,
                                   handleEmailInvitation,
                                   smsLoading,
                                   emailLoading,
                                   handleCreateParticipant
                               }) => {

    const [phoneNumber, setPhoneNumber] = useState(null);
    const [validPhoneNumber, setValidPhoneNumber] = useState(null);
    const [phoneValidationError, setPhoneValidationError] = useState("")

    const [email, setEmail] = useState(null);
    const [validEmail, setValidEmail] = useState(null);
    const [emailValidationError, setEmailValidationError] = useState("")

    const handleSetPhoneNumber = (e) => {
        setPhoneNumber(e);
        const [isValid, formattedNumber] = validatePhoneNumber(e);
        if (isValid || !e) {
            setPhoneValidationError("");
            setValidPhoneNumber(formattedNumber);
        } else {
            setValidPhoneNumber(null);
            setPhoneValidationError("Ogiltigt telefonnummer");
        }
    };

    const validatePhoneNumber = (e) => {
        let phone = e.replace("-", "");
        if (phone.substring(0, 2) === "07") {
            phone = "+46" + phone.substring(1, phone.length);
        } else if (phone.substring(0, 2) === "00") {
            phone.replace(phone.substring(0, 2), "+");
        }
        const re = new RegExp("^(([0]{1}|[0]{2}[4][6]|[+]{1}[4][6])[7]{1}\\d{1}-?\\d{7})$");
        if (re.test(phone)) {
            return [true, phone];
        }
        return [false, null];
    };

    const handleSetEmail = (e) => {
        setEmail(e);
        if (validator.isEmail(e) || !e) {
            setValidEmail(e);
            setEmailValidationError(null);
        } else {
            setValidEmail(null);
            setEmailValidationError("Ogiltig epost");
        }
    };

    const [smsSent, setSmsSent] = useState(null)
    const [emailSent, setEmailSent] = useState(null)
    const [showInvitationFailedModal, setShowInvitationFailedModal] = useState(false)

    const createParticipant = () => {
        handleCreateParticipant(true, null, validEmail, validPhoneNumber).then(response =>
            inviteExternal(response)
        );
    }

    const inviteExternal = (participantId) => {
        setEmailValidationError("")
        setPhoneValidationError("")
        validPhoneNumber && handleSmsInvitation(participantId).then((result) => {
            setSmsSent({ok: result})
        });
        validEmail && handleEmailInvitation(participantId).then((result) => {
            setEmailSent({ok: result})
        });
    }

    useEffect(() => {
        if (smsSent && emailSent) {
            if (!smsSent.ok && !emailSent.ok) {
                setShowInvitationFailedModal(true)
            }
        } else if (smsSent && !emailSent) {
            if (!smsSent.ok) {
                setShowInvitationFailedModal(true)
            }
        } else if (!smsSent && emailSent) {
            if (!emailSent.ok) {
                setShowInvitationFailedModal(true)
            }
        }

        setEmail(null)
        setPhoneNumber(null)
        setValidEmail(null)
        setValidPhoneNumber(null)
    }, [smsSent, emailSent]);
    
    const handleFormSubmit = (e) => {
      e.preventDefault()
      createParticipant()
    }

    return (
        <>
            <div>
                <Header>
                    <HeaderText>
                        Bjud in extern deltagare med sms/epost
                    </HeaderText>
                </Header>
                <form onSubmit={handleFormSubmit}>
                    <InviteExternal>
                        <div>
                            <PhoneInputWithLabelAndError placeholder={"Ange telefonnummer"}
                                                         handleChange={(e) => handleSetPhoneNumber(e)}
                                                         validationError={phoneValidationError}
                                                         value={phoneNumber || ""}>
                            </PhoneInputWithLabelAndError>
                        </div>
                        <div>
                            <EmailInputWithLabelAndError placeholder={"Ange epost"}
                                                         handleChange={(e) => handleSetEmail(e)}
                                                         validationError={emailValidationError}
                                                         value={email || ""}>

                            </EmailInputWithLabelAndError>
                        </div>
                        <InviteButton type={'submit'} disabled={!validPhoneNumber && !validEmail || smsLoading || emailLoading}
                                      >
                            <FontAwesomeIcon
                                icon={faUserPlus}
                            />Bjud in
                        </InviteButton>
                    </InviteExternal>
                </form>
            </div>
            <InvitationFailedModal showInvitationFailedModal={showInvitationFailedModal}
                                   setShowInvitationFailedModal={setShowInvitationFailedModal}>

            </InvitationFailedModal>
        </>
    )
}

const InviteExternal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: auto;
  background-color: #eff3f5;
  padding: 12px;
  border: 1px solid #E6EAEF;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const InviteButton = styled(SecondaryButton)`
  > svg {
    margin-right: 5px;
  }
`;