const defaultOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
};

const formatDateTime = (dateTimeString, options = {}) => {
    return Intl.DateTimeFormat("sv-SE", {...defaultOptions, ...options}).format(
        new Date(dateTimeString)
    )
}

const getFormattedTime = (dateTimeString) => {
    return new Date(dateTimeString).toLocaleTimeString('sv-SE', {timeStyle: 'short'});
}

export {formatDateTime, getFormattedTime};

