import {Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import {H2, SecondaryButton} from "@collabodoc/component-library";

export const InvitationFailedModal = ({showInvitationFailedModal, setShowInvitationFailedModal}) => {
    
    return(
        <Modal show={showInvitationFailedModal} onHide={() => setShowInvitationFailedModal(!showInvitationFailedModal)}>
            <ModalHeader>
                <H2>Inbjudan misslyckades</H2>
            </ModalHeader>
            <ModalBody>
                Deltagaren kunde inte bjudas in
            </ModalBody>
            <ModalFooter>
                <SecondaryButton onClick={() => setShowInvitationFailedModal(!showInvitationFailedModal)}>Stäng</SecondaryButton>
            </ModalFooter>
        </Modal>
    )
}