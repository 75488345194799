import {LoadingView, useGet, usePost} from "@collabodoc/component-library";
import {faPhone, faPhoneSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import properNameCase from "proper-name-case";
import React, {useContext} from "react";
import {generatePath, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {API_URLS, INTERNAL_URLS} from "../enums/Urls";
import {GlobalContext} from "../GlobalContext";
import {getFormattedTime} from "../utils/dateUtils";
import Header from "./Header";


const DashBoard = () => {
    const navigate = useNavigate();
    const {oidcUser, accessToken} = useContext(GlobalContext);
    const {doPost: createMeeting, isLoading: isLoading} = usePost(API_URLS.CREATE_MEETING, accessToken);
    const {response: history, isLoading: isLoadingHistory} = useGet(API_URLS.HISTORY, accessToken);

    const handleStartMeeting = (id) => {
        navigate(generatePath('meeting/:id', {id: id}))
    }

    const createMeetingHandler = async () => {
        createMeeting()
            .then(response => {
                navigate(generatePath(INTERNAL_URLS.MEETING, {id: response.id}));
            });
    };

    if (isLoading || isLoadingHistory) {
        return <LoadingView/>
    }

    const groupByDate = (array) => {
        const groups = array.reduce(function (groups, item) {
            const date = item.timeOfMeeting.split('T')[0]
            if (!groups[date]) {
                groups[date] = []
            }
            groups[date].push(item);
            return groups
        }, {});

        const groupArray = Object.keys(groups).map((date) => {
            return {
                date,
                meetings: groups[date]
            }
        });

        return groupArray;
    }

    const groupedHistoryData = history && groupByDate(history, 'timeOfMeeting');
    const ListOfMeetings = () => {
        return (groupedHistoryData.map((group, index) =>
            <div key={index}>
                <HistoryGroupDate>{group.date}</HistoryGroupDate>
                <HistoryRowWrapper>
                    {group.meetings.map((meeting, index) =>
                        <HistoryRow key={index} meeting={meeting}
                                    handleStartMeeting={() => handleStartMeeting(meeting.id)}/>
                    )}
                </HistoryRowWrapper>
            </div>
        ))
    };

    if (isLoading || isLoadingHistory) {
        return <LoadingView/>
    }

    return (
        <PageWrapper>
            <Header userName={oidcUser.firstname}
                    handleCreateMeeting={createMeetingHandler}/>
            <SectionHeader>
                <SectionHeaderText>Historik</SectionHeaderText>
            </SectionHeader>
            {
                history ?
                    <ListOfMeetings/>
                    : <LoadingView/>
            }

        </PageWrapper>
    )
}

const HistoryRow = ({meeting, handleStartMeeting}) => {

    const formatMeetingDuration = duration => {
        if (duration === null) return null;
        const meetingDuration = duration.split(':');
        const hours = parseInt(meetingDuration[0]);
        const minutes = parseInt(meetingDuration[1]);
        return hours > 0 ? `${hours} ${hours === 1 ? "timme" : "timmar"} ${minutes} min.` : `${minutes} min.`;
    }

    const isActive = !meeting.timeOfMeetingEnded;

    return meeting && (
        <HistoryRowBox isActive={isActive} key={meeting.id} onClick={isActive ? handleStartMeeting : undefined}>
            <CallCircle isActive={isActive}>
                <FontAwesomeIcon size={"xs"} icon={isActive ? faPhone : faPhoneSlash}/>
            </CallCircle>
            <ParticipantWrapper>
                <ParticipantInfo>
                    <strong>Samtal, kl. {getFormattedTime(meeting.timeOfMeeting)}</strong>
                    {
                        meeting.participants.map(item => properNameCase.convert(item.name)).join(", ")
                    }
                </ParticipantInfo>
            </ParticipantWrapper>
            <MeetingDuration>
                {formatMeetingDuration(meeting.meetingDuration)}
            </MeetingDuration>
        </HistoryRowBox>
    )
}

const HistoryRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const MeetingDuration = styled.div`
  color: ${
          ({theme}) => theme.colors.primary
  };
  align-self: center;
  margin-left: auto;
  min-width: fit-content;
`

const HistoryGroupDate = styled.p`
  margin-bottom: 0;
  color: ${
          ({theme}) => theme.colors.primary
  };
`

const ParticipantWrapper = styled.div`
  display: flex;
`

const HistoryRowBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f3f3f3;
  border: solid 0.5px #c6c6c6;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 2px 2px 2px lightgray;
  cursor: ${props => props.isActive ? 'pointer' : 'not-allowed'}
`

const ParticipantInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${
          ({theme}) => theme.colors.primary
  };
`

const CallCircle = styled.div`
  background-color: ${
          ({theme, isActive}) => isActive ? theme.colors.primary : theme.colors.danger
  };
  border-radius: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;

  > svg {
    min-height: 9px;
    min-width: 13.5px;

  }
`

const SectionHeaderText = styled.p`
  font-weight: bold;
  font-size: larger;
  margin-bottom: 0px;
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  padding: 12px;

  @media only screen and (min-width: 830px) {
    width: 800px;
  }
`

const SectionHeader = styled.div`
  padding-bottom: 4px;
  border-bottom: 1.25px solid ${
          ({theme}) => theme.colors.primary
  };
  color: ${
          ({theme}) => theme.colors.primary
  };
`

export default DashBoard;
