import {useOidc} from "@axa-fr/react-oidc";
import styled from "styled-components";
import {LoadingView} from "@collabodoc/component-library";
import {useEffect} from "react";

export const CustomSessionLost = ({configurationName}) => {
    const {login} = useOidc(configurationName);
    useEffect(() => {
        login().catch(console.error)
    }, [login]);
    return (
        <SessionLost>
            <SessionLostContainer>
                <h4>Din session har gått ut och du har blivit utloggad.</h4>
                <LoadingView/>
            </SessionLostContainer>
        </SessionLost>
    );
};
const SessionLost = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;
const SessionLostContainer = styled.div`
  text-align: center;
`;
