import {CacheBuster} from "@collabodoc/component-library";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import packageJson from "../package.json";
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <CacheBuster currentVersion={packageJson.version}>
            {(cacheBuster) => {
                if (cacheBuster.loading) return <div>Loading</div>;     
    
                if (!cacheBuster.isLatestVersion) {
                    cacheBuster.refreshCacheAndReload();
                }
                return <App />;
            }}
        </CacheBuster>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
