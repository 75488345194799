import {createGlobalStyle} from 'styled-components';
import proximanova from './ProximaNova/ProximaNova-Regular.ttf';
import proximanovabold from './ProximaNova/ProximaNova-Bold.ttf';

const FontProvider = createGlobalStyle`

    @font-face {
        font-family: 'Proxima Nova Bold';
        src: local('Proxima Nova Bold'), local('ProximaNovaBold'),
        url(${proximanovabold}) format('truetype');
        font-display: fallback;
    };

    @font-face {
        font-family: 'Proxima Nova';
        src: local('Proxima Nova'), local('ProximaNova'),
        url(${proximanova}) format('truetype');
        font-display: fallback;
    };

    body {
        font-family: ${props => props.theme.fontFamily};
    };
`;

export default FontProvider;