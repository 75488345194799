import {LoadingView, SearchBarWithLabel, useGet, usePost,} from "@collabodoc/component-library";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext, useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {Header, HeaderText} from "../Content/Style/styleElements";
import {API_URLS} from "../enums/Urls";
import {GlobalContext} from "../GlobalContext";
import {filter} from "../utils/filterParticipants";
import {ExternalInvite} from "./ExternalInvite";
import Invited from "./Invited";
import {UserRow} from "./UserRow";

const Participants = ({ showInviteParticipants}) => {
    const [searchText, setSearchText] = useState(null);
    const {id} = useParams();
    const {hubConnection, oidcUser, accessToken} = useContext(GlobalContext);

    const {
        response: invited,
        doGet: refetchInvitedList,
    } = useGet(API_URLS.PARTICIPANTS(id), accessToken);
    
    const {
        response: users,
        isLoading
    } = useGet(API_URLS.USERS, accessToken);
    
    const {
        doPost: createParticipant
    } = usePost(API_URLS.CREATE_PARTICIPANT(id), accessToken);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(hubConnection && hubConnection.state);
        if (hubConnection && hubConnection.state === "Connected") {
            hubConnection
                .invoke('SubscribeToMeeting', id)
                .then(() => console.debug("Subscribed to meeting updates."))
                .catch((err) => console.error(err.toString()));
            hubConnection.on('UserJoined', userid => {
                console.log(`User ${userid} connected`);
                refetchInvitedList()
            });
            hubConnection.on('UserAccepted', userid => {
                console.log(`User ${userid} accepted to meeting`);
            });
            hubConnection.on('UserLeft', (userid, properLeave) => {
                console.log(`User ${userid} left`);
                if (oidcUser && userid === oidcUser.sub && properLeave) {
                    navigate('../')
                }
                refetchInvitedList()
            });

        }
        return () => {
            if (hubConnection && hubConnection.state === "Connected") {
                hubConnection
                    .invoke('UnsubscribeToMeeting', id)
                    .then(() => console.debug("Unsubscribed to meeting updates."))
                    .catch((err) => console.error(err.toString()));
                hubConnection.off('UserJoined')

                hubConnection.off('UserLeft')
                hubConnection.off('UserAccepted')
            }
        }
    }, [hubConnection.state, oidcUser, id])
    
    const handleCreateParticipant = async (isExternal, internalUserId, email, phoneNumber) => {
        return createParticipant(
            {
                meetingId: id,
                isExternal: isExternal,
                userId: internalUserId,
                email: email,
                phoneNumber: phoneNumber
            });
    };

    const [smsLoading, setSmsLoading] = useState(false)
    const handleSmsInvitation = (participantId) => {
        setSmsLoading(true)
        const body = JSON.stringify({
            hostUserId: oidcUser.sub
        });

        return fetch(API_URLS.INVITE_SMS(id, participantId), {
            body,
            method: "POST",

            headers: {Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json"}
        }).then((response) => {
                setSmsLoading(false)
                refetchInvitedList()
                return response.ok;
            }
        )
    }

    const [emailLoading, setEmailLoading] = useState(false)
    const handleEmailInvitation = (participantId) => {
        setEmailLoading(true)
        const body = JSON.stringify({
            hostUserId: oidcUser.sub
        });
        return fetch(API_URLS.INVITE_EMAIL(id, participantId), {
            body,
            method: "POST",
            headers: {Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json"}
        }).then((response) => {
                setEmailLoading(false)
                refetchInvitedList()
                return response.ok;
            }
        )
    }

    const handleKickParticipant = (participantId) => {
        return fetch(API_URLS.KICK_USER(id, participantId), {
            method: "PUT",
            headers: {Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json"}
        }).then((response) => {
                refetchInvitedList();
                return response.ok;
            }
        )
    }

    if (isLoading || !users) {
        return <LoadingView/>
    }

    const filteredUsers = filter(users.filter(u => !invited.map(x => x.email).includes(u.email)), searchText);

    return (
        <Invite hidden={!showInviteParticipants}
                showInviteParticipants={showInviteParticipants}>
            <Invited key={invited.participantId}
                     invitedParticipants={invited}
                     handleEmailInvitation={handleEmailInvitation}
                     handleSmsInvitation={handleSmsInvitation}
                     handleKickParticipant={handleKickParticipant}>
            </Invited>
            <InternalInvite>
                <Header>
                    <HeaderText>
                        Bjud in deltagare
                    </HeaderText>
                </Header>
                <SearchBarWrapper>
                    <SearchBarWithLabel placeholder={"Sök deltagare"}
                                        whiteSearchBar={true}
                                        handleChange={(e) => setSearchText(e)}
                    />
                </SearchBarWrapper>
                <ScrollDiv>
                    <StyledTable>
                        <tbody>
                        {filteredUsers?.map((user) =>
                            <UserRow key={user.userId}
                                     user={user}
                                     handleSmsInvitation={handleSmsInvitation}
                                     handleEmailInvitation={handleEmailInvitation}
                                     handleCreateParticipant={handleCreateParticipant}/>
                        )}
                        </tbody>
                    </StyledTable>
                </ScrollDiv>
            </InternalInvite>
            <ExternalInvite handleSmsInvitation={handleSmsInvitation}
                            handleEmailInvitation={handleEmailInvitation}
                            smsLoading={smsLoading}
                            emailLoading={emailLoading}
                            handleCreateParticipant={handleCreateParticipant}/>
        </Invite>
    )
}

export default Participants;

export const Icon = styled(FontAwesomeIcon)`
  color: white;
  padding: 0 8px 0 0;
`;

const Invite = styled.div`
  display: ${(props) => props.showInviteParticipants ? 'flex;' : 'none;'};
  flex-direction: column;
  transition: all;
  gap: 10px;
  overflow: hidden;
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    flex: ${(props) => props.showInviteParticipants && '1'};
  }
`;

const StyledTable = styled(Table)`
  margin-bottom: 0;
`;

const SearchBarWrapper = styled.div`
  input {
    border: 1px solid #E6EAEF;
  }
`

const InternalInvite = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

const ScrollDiv = styled.div`
  overflow-y: auto;
  border: 1px solid #E6EAEF;
  border-top: none;
`;
