import {getRequestInit, LoadingView, SecondaryButton, useFetchy} from "@collabodoc/component-library";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {API_URLS, INTERNAL_URLS} from "../enums/Urls";
import {GlobalContext} from "../GlobalContext";
import CompodiumMeeting from "./CompodiumMeeting";
import Participants from "./Participants";


function MeetingView() {
    const navigate = useNavigate()
    const {accessToken} = useContext(GlobalContext);
    const {id} = useParams();
    const [showInviteParticipants, setShowInviteParticipants] = useState(false);

    const {
        data: tokenData,
        response,
        loading: isLoadingToken,
    } = useFetchy(API_URLS.MEETING_TOKEN_CAREGIVER(id), getRequestInit(accessToken));
    
    useEffect(() => {
        if(response && !response.ok){
            navigate(`../../${INTERNAL_URLS.NO_MEETING}`)
        }
        else if (response && response.ok){
            setShowInviteParticipants(tokenData?.isOwner)
        }
    }, [tokenData, response])
    
    const handleBack = () => {
        navigate('../');
    };

    if (!tokenData || isLoadingToken) {
        return <LoadingView/>
    }
    
    return (
            <Wrapper>
                <BackButtonContainer>
                    <StyledSecondaryButton onClick={handleBack}>
                        <FontAwesomeIcon
                            icon={faAngleLeft}/>
                        Tillbaka
                    </StyledSecondaryButton>
                    {tokenData?.isOwner &&
                        <StyledSecondaryButton onClick={() => setShowInviteParticipants(!showInviteParticipants)}>
                            {showInviteParticipants ? 'Stäng ' : 'Visa '}
                            deltagarlista
                        </StyledSecondaryButton>
                    }
                </BackButtonContainer>

                <MeetingWrapper showInviteParticipants={showInviteParticipants}>
                    <CompodiumMeeting meetingUrl={tokenData?.url}
                                      showInviteParticipants={showInviteParticipants}/>
                    {tokenData?.isOwner &&
                        <Participants showInviteParticipants={showInviteParticipants}/>
                    }
                </MeetingWrapper>
            </Wrapper>
    );
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MeetingWrapper = styled.div`
  //100% - height of back button + padding (38px + 24px = 62px)
  height: calc(100% - 62px);
  display: flex;
  flex: 1;
  gap: ${(props) => props.showInviteParticipants ? '8px;' : '0;'};
  padding: 0 12px 12px 12px;

  @media only screen and (max-width: 768px) {
    gap: 0;
    padding: 0;
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  gap: 4px;
`

export default MeetingView;
