import React, {useEffect, useState} from "react";
import styled from "styled-components";
import properNameCase from "proper-name-case";
import {
    P,
    SecondaryButton,
    TableBodyRow,
    Td
} from "@collabodoc/component-library";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InvitationFailedModal} from "./InvitationFailedModal";

export const UserRow = ({
                            user,
                            handleSmsInvitation,
                            handleEmailInvitation,
                            handleCreateParticipant
                        }) => {

    const [smsSent, setSmsSent] = useState(null)
    const [emailSent, setEmailSent] = useState(null)
    const [showInvitationFailedModal, setShowInvitationFailedModal] = useState(false)

    const inviteInternal = (participantId) => {
        user.phoneNumber && handleSmsInvitation(participantId).then((result) => {
            setSmsSent({ok: result})
        });
        user.email && handleEmailInvitation(participantId).then((result) => {
            setEmailSent({ok: result})
        });
    }

    useEffect(() => {
        if (smsSent && emailSent) {
            if (!smsSent.ok && !emailSent.ok) {
                setShowInvitationFailedModal(true)
            }
        } else if (smsSent && !emailSent) {
            if (!smsSent.ok) {
                setShowInvitationFailedModal(true)
            }
        } else if (!smsSent && emailSent) {
            if (!emailSent.ok) {
                setShowInvitationFailedModal(true)
            }
        }
    }, [smsSent, emailSent]);

    const createParticipant = () => {
        handleCreateParticipant(false, user.userId, user.email, user.phoneNumber).then(response =>
            inviteInternal(response)
        );
    }

    return (
        <>
            <TableBodyRow key={user.userId}>
                <TableData>
                    <div>
                        <Name>
                            {properNameCase.convert(user.name)}
                        </Name>
                        <SmallText>
                            {user.roleString}
                        </SmallText>
                    </div>
                    <ButtonWrapper>
                        <Button onClick={() => createParticipant()}>
                            <FontAwesomeIcon icon={faUserPlus}/>
                            Bjud in
                        </Button>
                    </ButtonWrapper>
                </TableData>
            </TableBodyRow>
            <InvitationFailedModal showInvitationFailedModal={showInvitationFailedModal}
                                   setShowInvitationFailedModal={setShowInvitationFailedModal}>
            </InvitationFailedModal>
        </>
    )
}


export const SmallText = styled(P)`
  margin-bottom: 0;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled(P)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
`;

export const TableData = styled(Td)`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  cursor: default;
`;

const Button = styled(SecondaryButton)`
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 8px;

  > svg {
    margin-right: 2px;
  }
`;
