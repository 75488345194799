import styled from "styled-components";

const CompodiumMeeting = ({meetingUrl, showInviteParticipants}) => {
    return (
        <Callframe showInviteParticipants={showInviteParticipants}>
            <iframe width="100%" height="100%"
                    src={meetingUrl}
                    allow="camera;microphone;fullscreen;display-capture;"
            >
            </iframe>
        </Callframe>
    )
}

const Callframe = styled.div`
  flex: 4;

  @media only screen and (max-width: 768px) {
    display: ${(props) => props.showInviteParticipants && 'none;'};
  }
`;

export default CompodiumMeeting;