import styled from "styled-components";
import {P} from "@collabodoc/component-library";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #6B7785;

  > svg {
    align-self: center;
  }
`;

export const HeaderText = styled(P)`
  padding: 8px;
  margin-bottom: 0;
  color: white;
`;
