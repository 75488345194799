
export const extractTenantIdFromHost = () => {
    if(!window.location){
        return null;
    }
    
    if(window.location.host.includes('localhost'))
        return window.env.tenant;
    
    const pattern = /^(.*?)-video\.collabodoc\.com$/;
    let match = pattern.exec(window.location.host);
    if(match){
        return match[1];
    }
    return null;
    
}

export const getTenantConfig = (tenantId) => window.env.tenants.find(t => t.id === tenantId);

export default extractTenantIdFromHost;