import {extractTenantIdFromHost} from "../utils/tenantUtils";

const getBaseUrl = (tenant) => {
    if(window.env.baseUrl)
        return `${window.env.baseUrl}/${tenant}`
    
    return `${window.location.origin}/${tenant}`
} 
const API_URLS_FOR_TENANT = (tenant) => {
    const baseUrl = getBaseUrl(tenant);
    return {
        CREATE_MEETING: `${baseUrl}/api/videomeetings`,
        MEETING_TOKEN_PATIENT: (meetingId, participantId) => `${baseUrl}/api/videomeetings/${meetingId}/getpatienttoken?participantId=${participantId}`,
        MEETING_TOKEN_CAREGIVER: (meetingId) => `${baseUrl}/api/videomeetings/${meetingId}/gettoken`,
        HISTORY: `${baseUrl}/api/videomeetings`,
        CREATE_PARTICIPANT: (meetingId) => `${baseUrl}/api/videomeetings/${meetingId}/participants`,
        USERS: `${baseUrl}/api/users`,
        PARTICIPANTS: (meetingId) => `${baseUrl}/api/videomeetings/${meetingId}/participants/`,
        INVITE_SMS: (meetingId, participantId) => `${baseUrl}/api/videomeetings/${meetingId}/participants/${participantId}/sms`,
        INVITE_EMAIL: (meetingId, participantId) => `${baseUrl}/api/videomeetings/${meetingId}/participants/${participantId}/email`,
        KICK_USER: (meetingId, participantId) => `${baseUrl}/api/videomeetings/${meetingId}/participants/${participantId}/kick`,
        EVENT_HUB: `${baseUrl}/hubs/event`
    }
}

const INTERNAL_URLS = {
    DASHBOARD: `caregiver`,
    MEETING: "meeting/:id",
    NO_MEETING: `nomeeting`
}

const tenant = extractTenantIdFromHost();
const EXTERNAL_URLS = {
    CAREGIVER_PORTAL_URL: `https://${tenant}.collabodoc.com`
};

const API_URLS = API_URLS_FOR_TENANT(tenant);

export {API_URLS, EXTERNAL_URLS, INTERNAL_URLS}
