const filter = (participants = [], searchString = "") => {
    if (searchString) {
        return multiFilter(participants, searchString, ['name', 'roleString', 'email']);
    }
    return participants
}

const multiFilter = (objects = [], searchString = "", properties = []) => {
    const searchWords = searchString.split(" ");
    return objects
        .filter(object => searchWords
            .every(searchWord => properties
                .some(property => object[property]
                    .toLowerCase()
                    .includes(searchWord.toLowerCase())
                )
            )
        );
}


export {filter, multiFilter}
