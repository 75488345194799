import {H2, H3} from "@collabodoc/component-library";
import React from "react";
import styled from "styled-components";

export const PatientEndMeeting = () => {
    return (<Wrapper>
        <H2>Mötet avslutat!</H2>
        <H3>Tack för din medverkan.</H3>
    </Wrapper>);
}


const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 57px;
`;
