import {
    defaultTheme as defaultComponentLibraryTheme
} from "@collabodoc/component-library";
import logo from "../Images/logo/collabodoc_light.png";

const defaultTheme = {
    ...defaultComponentLibraryTheme,
    logo: logo
}

export default defaultTheme;
