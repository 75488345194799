import {H1, PrimaryButton} from '@collabodoc/component-library';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideoCamera} from "@fortawesome/free-solid-svg-icons";

const Header = ({userName, handleCreateMeeting}) => {

    return (
        <HeaderContainer>
            <StyledH1>Välkommen {userName}!</StyledH1>
            <PrimaryButton
                onClick={handleCreateMeeting}>
                Nytt möte
            </PrimaryButton>
        </HeaderContainer>
    )
}

const HeaderContainer = styled.section`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 580px) {
    flex-direction: column;
    gap: 24px;
  }
`

const StyledH1 = styled(H1)`
  margin: 0;
`

export default Header;