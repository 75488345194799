import React from 'react';
import {NavBar} from "@collabodoc/component-library";
import {EXTERNAL_URLS} from "../enums/Urls";
import {useOidc} from "@axa-fr/react-oidc";


const NavMenu = ({logo}) => {
    const {logout, isAuthenticated} = useOidc();

    const logoutHandler = () => {
        if (isAuthenticated) {
            logout().catch(err => console.log(err));
        }
    };
    
    const items = [
        {label: "Logga ut", handler: logoutHandler}
    ]
    return <NavBar logo={logo} logohandler={() => window.location.href = EXTERNAL_URLS.CAREGIVER_PORTAL_URL}
                   items={items}/>
}

export default NavMenu;