import {useOidcAccessToken, useOidcUser} from "@axa-fr/react-oidc";
import {LoadingView} from "@collabodoc/component-library";
import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import React, {createContext, useEffect, useMemo} from 'react';
import {API_URLS} from "./enums/Urls";

export const GlobalContext = createContext(null);

export const GlobalContextProvider = (props) => {
    const {oidcUser} = useOidcUser();
    const {accessToken} = useOidcAccessToken();

    const hubConnection = useMemo(
        () =>
            new HubConnectionBuilder()
                .withUrl(API_URLS.EVENT_HUB)
                .configureLogging(LogLevel.Debug)
                .withAutomaticReconnect()
                .build(),
        []
    );
    useEffect(() => {
        if (hubConnection.state === "Disconnected") {
            hubConnection
                .start()
                .then(() => console.log("SignalR connection started."));
        }
        hubConnection.onreconnected(() => console.log("SignalR reconnected."));
        hubConnection.onclose(() => console.log("Failed to connect."));
    }, [hubConnection]);
    
    if (!accessToken || !oidcUser || !hubConnection)
        return <LoadingView/>
    return (
        <GlobalContext.Provider value={{hubConnection, oidcUser, accessToken}}>
            {props.children}
        </GlobalContext.Provider>)
}

 
