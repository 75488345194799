import {getRequestInit, LoadingView, useFetchy} from "@collabodoc/component-library";
import React, {useContext, useEffect, useState} from "react";
import {generatePath, useNavigate, useParams, useSearchParams} from "react-router-dom";
import styled from "styled-components";
import {API_URLS, INTERNAL_URLS} from "../../enums/Urls";
import {GlobalContext} from "../../GlobalContext";
import CompodiumMeeting from "../CompodiumMeeting";

function PatientMeetingView() {

    const {id, tenant} = useParams();
    const [searchParams] = useSearchParams();
    const [participantId, setParticipantId] = useState(searchParams.get('participant'));
    const {hubConnection, oidcUser, accessToken} = useContext(GlobalContext);

    const {
        data: tokenData,
        response,
        loading: isLoadingToken,
    } = useFetchy(API_URLS.MEETING_TOKEN_PATIENT(id, participantId), getRequestInit(accessToken));

    useEffect(() => {
        if (response && tokenData) {
            setParticipantId(tokenData.participantId)
        }
        if (response && !response.ok) {
            navigate(generatePath(`/patient/${INTERNAL_URLS.NO_MEETING}`))
        }
    }, [tokenData, response])

    const navigate = useNavigate();
    useEffect(() => {
        console.log(hubConnection && hubConnection.state);
        if (hubConnection && hubConnection.state === "Connected") {
            hubConnection
                .invoke('SubscribeToMeeting', id)
                .then(() => console.debug("Subscribed to meeting updates."))
                .catch((err) => console.error(err.toString()));

            hubConnection.on('UserAccepted', userid => {
                console.log(`User ${userid} accepted to meeting`);
            });

            hubConnection.on('UserJoined', userid => {
                console.log(`User ${userid} joined meeting`);
            });

            hubConnection.on('UserLeft', (userid, properLeave) => {
                console.log(`User left meeting ${userid}`);
                if (userid === participantId && properLeave) {

                    navigate(`${generatePath('/patient/:id/done', {id: id})}?participant=${userid}`);
                }
            });

        }

        return () => {
            if (hubConnection && hubConnection.state === "Connected") {
                hubConnection
                    .invoke('UnsubscribeToMeeting', id)
                    .then(() => console.debug("Unsubscribed to meeting updates."))
                    .catch((err) => console.error(err.toString()));
                hubConnection.off('UserAccepted');
                hubConnection.off('UserJoined');
                hubConnection.off('UserLeft');
            }
        }
    }, [hubConnection.state, id])


    if (isLoadingToken || !oidcUser) {
        return <LoadingView/>
    }


    return (
        <Wrapper>
            <CompodiumMeeting meetingUrl={tokenData?.url}/>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  height: 100%;
  display: flex;
  gap: 1rem;
  overflow: hidden;
`;

export default PatientMeetingView;
