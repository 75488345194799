import {OidcProvider, OidcSecure} from "@axa-fr/react-oidc";
import {LoadingView} from "@collabodoc/component-library";
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import styled, {ThemeProvider} from "styled-components";
import DashBoard from "./components/DashBoard";
import MeetingView from "./components/MeetingView";
import NavMenu from "./components/NavMenu";
import {NoMeeting} from "./components/NoMeeting";
import {PatientEndMeeting} from "./components/patient/PatientEndMeeting";
import PatientMeetingView from "./components/patient/PatientMeetingView";
import {FontProvider} from "./Content/fonts";
import {getTheme} from "./Content/themes/getTheme";
import {CustomSessionLost} from "./CustomSessionLost";
import {INTERNAL_URLS} from "./enums/Urls";
import {GlobalContextProvider} from "./GlobalContext";
import {getOidcConfig} from "./OidcConfiguration";

const theme = getTheme();
const oidcConfig = getOidcConfig(window.location.pathname);

const PatientIndex = () => {
    //TODO: Placeholder
    return (<>
        <div>Inget möte</div>
    </>);
}

const App = () => {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <FontProvider/>
                <StyledContainer>
                    <OidcProvider configuration={oidcConfig}
                                  authenticatingComponent={LoadingView}
                                  callbackSuccessComponent={LoadingView}
                                  sessionLostComponent={CustomSessionLost}
                                  loadingComponent={LoadingView}>
                        <OidcSecure>
                            <GlobalContextProvider>
                                <NavMenu logo={theme.logo}/>
                                <Routes>
                                    <Route path="caregiver">
                                        <Route path={INTERNAL_URLS.MEETING}
                                               element={<MeetingView/>}/>
                                        <Route index element={<DashBoard/>}/>
                                    </Route>
                                    <Route path="patient">
                                        <Route path={INTERNAL_URLS.MEETING}
                                               element={<PatientMeetingView/>}/>
                                        <Route path={':meetingId/done'}
                                               element={<PatientEndMeeting/>}/>
                                        <Route path={INTERNAL_URLS.NO_MEETING}
                                               element={<NoMeeting/>}/>
                                        <Route index element={<PatientIndex/>}/>
                                    </Route>
                                    <Route path={INTERNAL_URLS.NO_MEETING}
                                           element={<NoMeeting/>}/>
                                    <Route index element={<Navigate to={'caregiver'}/>}/>
                                </Routes>
                            </GlobalContextProvider>
                        </OidcSecure>
                    </OidcProvider>
                </StyledContainer>
            </ThemeProvider>
        </BrowserRouter>
    )
}

const StyledContainer = styled.div`
  height: 100dvh;
  padding-top: 57px;
`;

export default App;
