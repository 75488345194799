import {extractTenantIdFromHost, getTenantConfig} from "./utils/tenantUtils";

const baseOidcConfiguration = {
    service_worker_relative_url: '/OidcServiceWorker.js',
    redirect_uri: `${window.location.origin}/caregiver/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/caregiver/authentication/silent-callback`,
    service_worker_only: true,
    loadUserInfo: true,
    monitor_session: false
};

const getOidcConfig = (pathname) => {
    const tenant = extractTenantIdFromHost();
    if (!tenant) throw new Error("No tenant!!!");
    const tenantConfig = getTenantConfig(tenant);
    
    const patientConfig = {
        ...baseOidcConfiguration,
        authority: window.env.patient.authority,
        client_id: window.env.patient.clientId,
        scope: 'openid profile videocall_api offline_access',
        redirect_uri: `${window.location.origin}/patient/authentication/callback`,
        silent_redirect_uri: `${window.location.origin}/patient/authentication/silent-callback`,
    }

    const careGiverConfig = {
        ...baseOidcConfiguration,
        authority: tenantConfig.authority,
        client_id: tenantConfig.clientId,
        scope: 'openid profile custom role videocall_api offline_access',
        redirect_uri: `${window.location.origin}/caregiver/authentication/callback`,
        silent_redirect_uri: `${window.location.origin}/caregiver/authentication/silent-callback`,
    }
    return pathname.includes('/patient') ? patientConfig : careGiverConfig;
}

export {getOidcConfig};
