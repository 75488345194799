import properNameCase from "proper-name-case";
import styled from "styled-components";
import {Header, HeaderText} from "../Content/Style/styleElements";
import {P} from "@collabodoc/component-library";
import {InvitedRow} from "./InvitedRow";

const Invited = ({
                     invitedParticipants = [],
                     handleSmsInvitation,
                     handleEmailInvitation,
                     handleKickParticipant
                 }) => {

    const internalInvites = invitedParticipants.filter(u => !u.isExternal)
    const externalInvites = invitedParticipants.filter(u => u.isExternal)

    const inviteAgainHandler = (participant) => {
        participant.phone && handleSmsInvitation(participant.participantId, participant.isExternal);
        participant.email && handleEmailInvitation(participant.participantId, participant.isExternal);
    }

    return (
        <div>
            <Header>
                <HeaderText>
                    Deltagare/Inbjudna
                </HeaderText>
            </Header>
            <InvitedSection>
                {!invitedParticipants.length &&
                    <Invites>Bjud in deltagare nedan</Invites>
                }
                {internalInvites.map((p, index) =>
                    <InvitedRow key={index} 
                                invited={p}
                                sendInvitation={inviteAgainHandler}
                                handleKickParticipant={handleKickParticipant}/>
                )}
                {externalInvites.map((p, index) => {
                        let text;
                        if (p.name) {
                            text = properNameCase.convert(p.name);
                        } else if (p.phone && !p.email) {
                            text = p.phone;
                        } else if (p.email && !p.phone) {
                            text = p.email
                        } else {
                            text = `${p.email}, ${p.phone}`;
                        }
                        return <InvitedRow key={index} 
                                           invited={p}
                                           sendInvitation={inviteAgainHandler}
                                           handleKickParticipant={handleKickParticipant}>
                            {text}
                        </InvitedRow>
                    }
                )}
            </InvitedSection>
        </div>
    )
}

export default Invited;

const InvitedSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  background-color: #eff3f5;
  padding: 12px;
  border: 1px solid #E6EAEF;
  gap: 4px;
`;

const Invites = styled(P)`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;
