import {H1, H2, NavBar} from "@collabodoc/component-library";
import styled from "styled-components";
import {getTheme} from "../Content/themes/getTheme";

export const NoMeeting = () => {
    const theme = getTheme();

    return (
        <>
            <NavBar logo={theme.logo}/>
            <NoMeetingWrapper>
                <H2>Inget möte hittades.</H2>
                <H2>Antingen är mötet avslutat eller så finns det inget möte på den angivna adressen.</H2>
            </NoMeetingWrapper>
        </>
    )
}

const NoMeetingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10%;
`;