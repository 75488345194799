import {P, SecondaryButton} from "@collabodoc/component-library";
import {faUser, faUserMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import properNameCase from "proper-name-case";
import React, {useEffect, useState} from "react";
import styled from "styled-components";

export const InvitedRow = ({invited, sendInvitation, children, handleKickParticipant}) => {

    const [showInviteAgainButton, setShowInviteAgainButton] = useState(false);
    const inviteAgainHandler = () => {
        setShowInviteAgainButton(false);
        showReInviteButton();
        sendInvitation(invited);
    }

    useEffect(() => {
        showReInviteButton();
    }, []);

    const showReInviteButton = () => {
        const timer = setTimeout(() => {
            setShowInviteAgainButton(true);
        }, 10000);
    }
    
    return (
        <Row>
            <InvitedWrapper>
                <FontAwesomeIcon color={invited.isInMeeting ? "green" : "grey"} icon={faUser}/>
                <InvitedParagraph>{children ?? properNameCase.convert(invited.name)}</InvitedParagraph>
            </InvitedWrapper>
            <ButtonWrapper>
                <InviteAgainButton disabled={!showInviteAgainButton} onClick={inviteAgainHandler}>Bjud in igen</InviteAgainButton>
                <InviteAgainButton onClick={() => handleKickParticipant(invited.participantId)}><FontAwesomeIcon icon={faUserMinus}/></InviteAgainButton>
            </ButtonWrapper>
        </Row>
    )
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
`

const InvitedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const InvitedParagraph = styled(P)`
  margin: 2px 0 0 0;
`

const InviteAgainButton = styled(SecondaryButton)`
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: flex-end;
  white-space: nowrap;
  padding: 4px 8px 4px 8px;
`
